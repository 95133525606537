@import url(https://fonts.googleapis.com/css?family=Patrick+Hand+SC|Roboto&display=swap);
body {
  font-family: 'Roboto', sans-serif;
}

a {
  color: rgb(15, 51, 6);
}

a:hover {
  color: rgb(114, 161, 118);
}
